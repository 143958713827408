/* eslint-disable   import/prefer-default-export */

export const LAZY_LOADING = {
  offset: 800,
}

export const MEDIA_QUERY = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
}
